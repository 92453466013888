<template>
    <div>
        <div class="col-12 px-0">            <div class="container-fluid p-dash-cliente-master pb-2 pt-3">
    <div class="col-12">
        <div class="row">
            <div class="col-auto align-self-center text-right pr-0">
                <b-link :to="{name: 'Dashboard'}">
                    <a class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta" style="background:var(--color-broker);" href="#"><i style="background:var(--color-broker);" class="fas fa-arrow-left"></i></a>
                </b-link>
               
            </div>
            <div class="col-10 align-self-center">
                <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">alterar senha</h3>
                <div class="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb m-0 p-0">
                            <li class="breadcrumb-item"><a href="?app=dashboard.php">dashboard / alterar senha</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 pt-1 px-0">
        <div class="col-12">
            <div class="card card-bg-dash">
                <div class="card-body">
                 
                        <div class="form-body">
                            <div class="row">
                                <div class="col-12 pb-2">
                                    <h4 class="card-title">Senha de acesso</h4>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Resetar senha </label>
                                        <input v-model="password" type="password" class="form-control" id="passtext" placeholder="********">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Confirmar senha </label>
                                        <input v-model="password_confirmation" type="password" class="form-control" id="passtext" placeholder="********">
                                    </div>
                                    <small id="name1" style="background:var(--color-broker);" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                </div>
                                <div class="col-12">
                                    <!-- <div class="form-group">
                                        <div class="col-12 py-2">
                                            <i class="fab fa-facebook font-20 align-middle"></i> Rafael Dias
                                        </div>
                                        <button type="submit" class="btn btn-danger">Desvincular conta <i class="pl-1 far fa-trash-alt"></i></button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="form-actions">
                            <div class="text-right">
                                <b-link :to="{name: 'Dashboard'}"><button type="button" class="btn btn-dark"> Cancelar</button></b-link>
                                <button type="button" style="background:var(--color-broker);" @click="changePassword" class="btn btn-info">Alterar</button>
                            </div>
                        </div>
               
                </div>
            </div>
        </div>
    </div>
</div></div>
        <footer class="footer text-center text-muted p-0">
        <div class="col-12 py-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                        © {{new Date(Date.now()).getFullYear()}} | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a :href="'https://'+site+'/termos-de-uso'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de uso</a> | <a :href="'https://'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Política de privacidade</a> | <a href="https://r4marketing.com.br/termos-de-uso" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de Uso</a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                        <a href="https://qsuite.com.br" target="_blank"><img src="@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 23px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
</template>
<script>

import ChangePasswordService from "@/services/resources/ChangePassword";

const ChangePassword = ChangePasswordService.build();


  export default {
    name: "Dashboard",
    data() {
        return {
             site:"",
            password: '',
            password_confirmation: ''
        };
    },
    watch: {
      
      
    },
   
    methods: {
        changePassword() {
            if(this.password.length < 8 ) {

                this.$bvToast.toast('Sua senha deve ser maior que 8 dígitos', {
                        title: 'Trocar senha',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                return;
            }

            if(this.password_confirmation !== this.password) {

                this.$bvToast.toast('Senhas não conferem', {
                        title: 'Trocar senha',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                return;
            }

            ChangePassword.create({password: this.password}).then(response => {
                this.$bvToast.toast('Senha atualizada com sucesso', {
                        title: 'Trocar senha',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.password = '';
                    this.password_confirmation = '';
            }).catch(err => {
                this.$bvToast.toast('Erro ao atualizar senha', {
                        title: 'Trocar senha',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
            });

        }
       
    },
   
    mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
     this.site=window.location.host
        
    },
    computed: {
        user() {
            return this.$store.getters.getProfile;
        },
    },
    components: {}
}


</script>

