import Rest from '@/services/Rest';

/**
 * @typedef {ChangePassword}
 */
export default class ChangePassword extends Rest {
  /**
   * @type {String}
   */
  static resource = '/change-password'
}
